import axios from "axios";
import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import logo from "../../../assets/logo/logo.png";

const ViewPrescriptionModal = ({id, }) => {
  const [showModal, setShowModal] = useState(true);
  const printModal = () => {
    const divToPrint = document.getElementById("printable-content");
    const popupWin = window.open("", "_blank", "width=900,height=900");
    popupWin.document.write(`
          <html>
            <head>
              <title>Prescription</title>
              <style>
                body {
                  margin: 20px;
                 
                  font-family: Arial, sans-serif;
                }
                  p{
                  
                   font-size: 12px;
                   
                   }
                   h4{
                   
                    font-size: 15px;
                    }
                    address{
                     font-size: 12px;
                   
                    }
                  strong{
                  font-weight: 600;
    
                  }
                .container {
                  width: 100%;
                  margin: 0 auto;
                }
                .text-center {
                  text-align: center;
                }
                .text-start {
                  text-align: left;
                }
                .border {
                  border: 1px solid #000;
                  padding: 10px;
                }
                .bordered-table {
                  width: 100%;
                  border-collapse: collapse;
                }
                .bordered-table th, .bordered-table td {
                  border: 1px solid #000;
                  padding: 8px;
                  text-align: left;
                }
                .fw-bold {
                  font-weight: 500;
                }
                .text-decoration-underline {
                  text-decoration: underline;
                }
                .m-0 {
                  margin: 0;
                }
                .mt-2 {
                  margin-top: 20px;
                }
                .mb-2 {
                  margin-bottom: 20px;
                }
                .p-2 {
                  padding: 10px;
                }
                .px-2 {
                  padding: 20px;
                }
                .rounded {
                  border-radius: 5px;
                }
                  .flex{
                  display:flex;
                  justify-content: center;
                  
                  }
                  .flex1{
                  display:flex;
                  justify-content: space-between;
                  
                  }
              </style>
            </head>
            <body onload="window.print()">
              <div className="container">
                ${divToPrint.innerHTML}
              </div>
            </body>
          </html>
        `);
    popupWin.document.close();
  };
  const pharmacyList = [
    {
      pharmacyid: "1",
      pharmacyName: "ABC Pharmacy",
      address: "Plot 2, Sector 1, Ghaziabad, Uttar Pradesh",
      contactNo: "9999999999",
      email: "abc@pharmacy.com",
      openingHours: "9:00 AM - 7:00 PM",
    },
    {
      pharmacyid: "2",
      pharmacyName: "XYZ Pharmacy",
      address: "Plot 3, Sector 2, Ghaziabad, Uttar Pradesh",
      contactNo: "8888888888",
      email: "xyz@pharmacy.com",
      openingHours: "10:00 AM - 6:00 PM",
    },
    {
      pharmacyid: "3",
      pharmacyName: "PQR Pharmacy",
      address: "Plot 4, Sector 3, Ghaziabad, Uttar Pradesh",
      contactNo: "7777777777",
      email: "pqr@pharmacy.com",
      openingHours: "8:00 AM - 5:00 PM",
    },
  ];

  const laboratory = [
    {
      labid: "1",
      labName: "ABC Laboratory",
      address: "Plot 5, Sector 1, Ghaziabad, Uttar Pradesh",
      contactNo: "9999999999",
      email: "abc@lab.com",
      openingHours: "9:00 AM - 7:00 PM",
    },
    {
      labid: "2",
      labName: "XYZ Laboratory",
      address: "Plot 6, Sector 2, Ghaziabad, Uttar Pradesh",
      contactNo: "8888888888",
      email: "xyz@lab.com",
      openingHours: "10:00 AM - 6:00 PM",
    },
    {
      labid: "3",
      labName: "PQR Laboratory",
      address: "Plot 7, Sector 3, Ghaziabad, Uttar Pradesh",
      contactNo: "7777777777",
      email: "pqr@lab.com",
      openingHours: "8:00 AM - 5:00 PM",
    },
  ];

  const [prescriptionData, setPrescriptionData] = useState({
    patientName: "",
    age: "",
    age1: "",
    sex: "",
    date: "",
    time: "",
    mobile: "",
    email: "",
    patientId: "",
    suggestedtests: "",
    symptoms: "",
    findings: "",
    Vitals: "",
    diagnosis: "",
    followUp: "",
    medicines: [],
    doctorName: "",
    doctorRegNo: "",
  });

  useEffect(() => {
    handleSearch();
  }, []);


  const handleSearch = async () => {
    // if ( !id) {
    //   alert("Please enter prescription ID");
    //   return;
    // }

    try {
      // const prescriptionId = atob(id);
      const res = await axios.post(
        `${process.env.REACT_APP_BASEURL_DOCTOR}/api/v1/doctor/getPrescription`,
        { prescriptionid: 108 }
      );

      if (res.status === 200) {
        const prescription = res.data.prescriptionresult[0];
        setPrescriptionData({
          patientName: `${prescription.firstname} ${prescription.lastname}`,
          age: prescription.dob,
          age1: prescription.dob,
          sex: prescription.gender,
          date: prescription.followupdate,
          time: prescription.followupdate,
          mobile: prescription.mobilenumber,
          email: prescription.emailid,
          patientId: prescription.patientid,
          suggestedtests: res.data.labtestresult,
          symptoms: prescription.symptoms,
          findings: prescription.clinicalfindings,
          Vitals: prescription.vitals,
          diagnosis: prescription.diagnosis,
          followUp: prescription.followupdate,
          medicines: res.data.medicineresult,
          doctorName: prescription.doctorname,
        });
        // setShow(true);
      }
    } catch (error) {
      console.error(error);
      // alert("Error while fetching prescription details");
    }
  };

  const onCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {showModal && <div className="modal-backdrop fade show"></div>}
      <div
        className={`modal fade ${showModal ? "show d-block" : ""}`}
        tabIndex="-1"
        aria-labelledby="searchPatientLabel"
        aria-hidden="true"
      >
        {showModal && (
          <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title text-center" id="searchPatientLabel">
                  Prescription Information
                </h6>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onCloseModal}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="container">
                  <h4 className="text- mt-2">Prescription Preview</h4>
                  <div className="border p-2 px-4" id="printable-content">
                    <div className="row flex">
                      <div className="col-2 d-flex justify-content-end ">
                        <img
                          src="https://marketplace.canva.com/EAE8eSD-Zyo/1/0/1600w/canva-blue%2C-white-and-green-medical-care-logo-oz1ox2GedbU.jpg"
                          alt=""
                          height={120}
                          width={120}
                        />
                      </div>
                      <div className="col-10 d-flex justify-content-start ">
                        <div className="">
                          <h4 className="text-center">
                            Radhe Krishna Multispeciality Hospital and Research
                            Center
                          </h4>
                          <address className="text-center m-0 ">
                            Vaishali, Sector 1, Ghaziabad, Uttar Pradesh
                            (201013)
                          </address>
                          <address className="m-0 text-center">
                            Phone: <strong>+919648316865</strong> {"  "}, Email:{" "}
                            <strong>Email@expalle.com</strong>
                          </address>
                        </div>
                      </div>
                    </div>
                    <hr className="text-primary m-0 mb-2" />
                    <div className="row border rounded p-3">
                      <p className="text-center text-decoration-underline mb-1">
                        <strong> Patient Information</strong>
                      </p>
                      <div className="flex1 row">
                        <div className="col-4">
                          <p className="m-0">
                            Patient Name:{" "}
                            <strong>{prescriptionData.patientName}</strong>
                          </p>
                          <p className="m-0 ">
                            DOB:{" "}
                            <strong>
                              {dayjs(prescriptionData.age).format("D-MMM-YYYY")}
                            </strong>
                          </p>
                          <p className="m-0 ">
                            Gender: <strong>{prescriptionData.sex}</strong>
                          </p>
                        </div>
                        <div className="col-4">
                          {/* <p className="m-0">
                Date: <strong>{dayjs(prescriptionData.date).format("D-MMM-YYYY")}</strong>
              </p> */}
                          <p className="m-0">
                            Contact: <strong>{prescriptionData.mobile}</strong>
                          </p>
                          <p className="m-0">
                            Email: <strong>{prescriptionData.email}</strong>
                          </p>
                        </div>
                        <div className="col-4">
                          <p className="m-0">
                            PatientID: <strong>{prescriptionData.id}</strong>
                          </p>
                          <p className="m-0">
                            Address: <strong>{prescriptionData.address}</strong>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mt-2">
                      <h5 className="text-center fw-semibold text-decoration-underline">
                        Clinical Details
                      </h5>
                      <p className="m-0">
                        <strong>Symptoms:</strong> {prescriptionData.symptoms}
                      </p>
                      <p className="m-0">
                        <strong>Findings:</strong> {prescriptionData.findings}
                      </p>
                      <p className="m-0">
                        <strong>Vitals:</strong> {prescriptionData.vitals}
                      </p>
                      <p className="m-0">
                        <strong>Diagnosis:</strong> {prescriptionData.diagnosis}
                      </p>
                      <p className="m-0">
                        <strong>Test Suggestions: </strong>
                        {prescriptionData &&
                          prescriptionData.suggestedtests &&
                          prescriptionData.suggestedtests.map((test, index) => (
                            <span key={index}>
                              {test.labtestName || test.suggestedtests},{" "}
                            </span>
                          ))}
                      </p>
                      <p className="m-0">
                        <strong>Follow Up Date: </strong>{" "}
                        {dayjs(prescriptionData.followUp).format("D-MMM-YYYY")}
                      </p>
                    </div>

                    <hr />
                    <p>
                      <strong>Medicines:</strong>
                    </p>
                    <table className=" table bordered-table table-responsive">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Frequency</th>
                          {/* <th>Dose</th> */}
                          <th>Duration</th>
                          <th>Notes</th>
                        </tr>
                      </thead>
                      <tbody>
                        {prescriptionData.medicines.map((medicine, index) => (
                          <tr key={index}>
                            <td>
                              {medicine.medicine || medicine.medicine_name}
                            </td>
                            <td>{medicine.fName || medicine.frequencyname}</td>
                            {/* <td>{medicine.dose}</td> */}
                            <td>{medicine.duration}</td>
                            <td>{medicine.notes}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <hr />
                    <div className="doc-info">
                      <strong>Doctor Information:</strong>
                      <div className="row flex1">
                        <div className="col-6">
                          <p className="m-0">
                            Name :{" "}
                            <strong> Dr. {prescriptionData.doctorName}</strong>
                          </p>
                          <p className="m-0">
                            {" "}
                            Phone No: {prescriptionData.doctorRegNo}
                          </p>
                        </div>
                        <div className="col-6">
                          <p className="m-0">
                            Date / Time:{" "}
                            {dayjs(new Date().toLocaleDateString()).format(
                              "D-MMM-YYYY"
                            )}{" "}
                            {new Date().toLocaleTimeString()}
                          </p>
                          <p className="m-0">
                            Signature:{" "}
                            <strong>{prescriptionData.doctorName}</strong>{" "}
                            (Digitally Signed with BaelHealth{" "}
                            <img src={logo} alt="Logo" height={30} />)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="print d-flex justify-content-between gap-2">
                    <div className="d-flex mt-2 gap-2">
                      <div className="pharmacy">
                        <div className="d-flex gap-2">
                          <select name="" id="" className="form-select">
                            <option value="">Select Pharmacy</option>
                            {pharmacyList &&
                              pharmacyList.map((pharmacy, index) => (
                                <option key={index} value={pharmacy.pharmacyid}>
                                  {pharmacy.pharmacyName}
                                </option>
                              ))}
                          </select>
                          {/* <button className="btn  btn-outline-primary"> Send</button> */}
                        </div>
                      </div>
                      <div className="lab ">
                        <div className="d-flex gap-2">
                          <select name="" id="" className="form-select">
                            <option value="">Select Lab</option>
                            {laboratory &&
                              laboratory.map((lab, index) => (
                                <option key={index} value={lab.id}>
                                  {lab.labName}
                                </option>
                              ))}
                          </select>
                          <button className="btn  btn-outline-primary">
                            {" "}
                            Send
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex">
                      <button
                        type="button"
                        className="btn btn-outline-secondary mt-2 mb-4 me-4"
                        // onClick={onButtonClick}
                      >
                        Back
                      </button>

                      <button
                        type="button"
                        className="btn btn-outline-success mt-2 mb-4"
                        onClick={printModal}
                      >
                        Print
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ViewPrescriptionModal;
