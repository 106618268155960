import React, { useEffect, useState } from "react";
import { createContext, useContext } from "react";
const userContext = createContext();

export const UserProviderPharma = ({ children }) => {
  // const userData = {
  //   id: "",
  //   loginid: null,
  //   name: "",
  //   role: null,
  //   profileimg: null,
  //   licenseid: null,
  //   type: "",
  //   mobilenumber: "",
  //   email: "",
  //   fclty_id: "",
  // };

  const [PharmaUser, setPharmaUser] = useState(() => {
    const savedStaff =sessionStorage.getItem("pharmaUser");
    return savedStaff
    ? JSON.parse(savedStaff)
    : {
        id: "",
        loginid: null,
        name: "",
        role: null,
        profileimg: null,
        licenseid: null,
        type: "",
        mobilenumber: "",
        email: "",
        fclty_id: "",
      };
    
  });
  useEffect(() => {
    sessionStorage.setItem("pharmaUser",  JSON.stringify(PharmaUser));
  }, [PharmaUser]);
  return (
    <userContext.Provider value={{ PharmaUser, setPharmaUser }}>
      {children}
    </userContext.Provider>
  );
};

export const UseUser = () => useContext(userContext);
