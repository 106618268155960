import { createContext, useContext, useEffect, useState } from "react";
const WriteprescriptionContext = createContext();
export const WritePrescriptionProvider = ({ children }) => {
  const [prescription, setPrescription] = useState({
    doctorid: "",
    patientid: "",
    symptoms: "",
    clinicalfindings: "",
    medicines: [
      {
        medicineid: "",
        notes: "",
        frequency: "",
        duration: "",
        dose: "",
        medicine: "",
        fName: "",
        frequencyname: "",
        medicine_name: "",
      },
    ],
    diagnosis: "",
    adviceremarks: "",
    suggestedtests: [],
    // suggestedtests: [{}],
    followupdate: "",
  });

  return (
    <WriteprescriptionContext.Provider
      value={{ prescription, setPrescription }}
    >
      {children}
    </WriteprescriptionContext.Provider>
  );
};
export const useWritePrescription = () => useContext(WriteprescriptionContext);
