
import React, { useEffect, useState } from "react";
import { createContext, useContext } from "react";
const userContext = createContext();

export const UserProvider = ({ children }) => {
  const [PharmaUser, setPharmaUser] = useState(() => {
    const savedStaff = sessionStorage.getItem("labUser");
    return savedStaff
      ? JSON.parse(savedStaff)
      : {
          id: "122vrg12344",
          image: "https://via.placeholder.com/100",
          name: "John Doe",
          email: "johndoe@example.com",
          role: "Admin",
          phone: "9648316865",
          pharmacy: " Delhi medicical Store Inc",
          prescriptionId: "123",
        };
  });
  useEffect(() => {
    sessionStorage.setItem("labUser", JSON.stringify(PharmaUser));
  }, [PharmaUser]);
  return (
    <userContext.Provider value={{ PharmaUser, setPharmaUser }}>
      {children}
    </userContext.Provider>
  );
};

export const UseUser = () => useContext(userContext);
