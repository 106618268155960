// apiService.js
import { apiClient, apiClientDoctor, apiClientMedicineScheduler } from './apiClient'; 

// Example API functions
const apiService = {
  //  Login
  sentLoginotp: (data) => apiClient.post("/generate-otp", data),
  //  Verify Otp
  verifyLoginOtp: (data) => apiClient.post("/user-login", data),
  // get All facility
  getAllFacilities: () => apiClient.post("/getAllFacility"),

  // get All Speciality
  getAllSpeciality: () => apiClient.get("/getSpecialist"),
  //   Verify Phone Number
  sendOtpVerifyNumber: (data) =>
    apiClient.post(`/validate-register-mobile`, data),
  //  verify otp
  verifyOtp: (otpData) => apiClient.post(`/validate-otp`, otpData),
  //  Get Parent Facility
  getParentsFacilities: () => apiClient.post("/getAllFacility"),

  //  Register Patient
  registerPatient: (data) =>
    apiClient.post("/registerPatient", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  //  Get All Patients
  getAllPatients: () => apiClient.get("/getAllPatients"),
  //  update Patient
  updatePatient: (data) =>
    apiClient.post("/updatePatient", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  //  Get Doctor List
  getDoctorList: (data) => apiClient.post("/getDoctorList", data),
  //  Register Doctor
  registerDoctor: (data) => apiClient.post("/registerDoctor", data),
  updateDoctor: (data) => apiClient.post("/updateDoctor", data),
  //  Get All Facility Staff
  getAllFacilityStaff: (data) => apiClient.post(`/getAllFacilityStaff`, data),
  //  Register Facility Staff
  registerFacilityStaff: (staffData) =>
    apiClient.post(`/addFacilityStaff`, staffData, {
      Headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  //  update Facility Staff
  updateFacilityStaff: (staffData) =>
    apiClient.post(`/updateFacilityStaff`, staffData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  //  Get All Specialization
  getAllSpecializations: () => apiClient.post(`/getAllSpecializations`),
  getDoctorByFacilityID :(data)=> apiClient.get(`/getDoctorListByClinicId/${data}`),
  fetchAppointments :(data)=> apiClient.post("/getClinicDoctorAppointment",data),
  getTestResults :(data)=> apiClient.post("/getTestResults",data),
  getExpenses :(data)=>apiClient.post("/getExpenses",data),
  addVitals :(data)=> apiClient.post("/savePatientVitals",data),
  getPatientVitals:(data)=> apiClient.post("/getPatientVitals",data),
  getDoctorListByClinicId:(data)=> apiClient.get(`/getDoctorListByClinicId/${data}`),
  createAppointment :(data)=>apiClient.post("/createAppointment",data),
  getAllAppointmentsByClinicId:(data)=> apiClient.post("/getAllAppointment",data),
  getHealthRecord :(data)=> apiClient.post("/getHealthRecords",data),
  createRecord :(data)=> apiClient.post("/createRecord",data),

  searchPatient : (data)=> apiClientDoctor.post("/api/v1/patient/searchPatient",data),
  getfrequency :()=> apiClientDoctor.get("/api/v1/frequency/getFrequency"),
  getAllTest :()=> apiClientDoctor.get("/api/v1/labtest/tests"),
  getAllSymptoms :()=> apiClientDoctor.post("/api/v1/symptoms/searchsymtoms"),
  searchMedicine :(data)=> apiClientDoctor.post("/api/v1/medicine/searchMedicine", data),
  getlatestVitals: (data)=> apiClient.post("/getAllPatientVitals",data),
  getPrescrriptionByPatientId:(data)=> apiClientDoctor.get("/api/v1/doctor/getPrescriptionByPatientId",{params: data}),
  getPrescriptionByPresId:(data)=> apiClientDoctor.post("/api/v1/doctor/getPrescription",data),
  submitPrescription :(data)=>apiClientDoctor.post("/api/v1/doctor/addPrescription",data),
 
  getNotesLabel :()=> apiClientMedicineScheduler.get("/api/v1/notelabel/getLabelList"),
  getAllNotes :(data)=> apiClientMedicineScheduler.post("/api/v1/note/getAllNotes",data),
  createNote :(data)=> apiClientMedicineScheduler.post("/api/v1/note/createNote",data),
  
};

export default apiService;
