import React, { useEffect, useState } from "react";
import axios from "axios";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import dayjs from "dayjs";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import debounce from "lodash.debounce";
import { useClinicStaff } from "../../context/ClinicContext";
import { TimePicker } from "@mui/x-date-pickers";
import { Grid, Button, Typography, Box, TextField, Badge } from "@mui/material";
import Breadcrumb from "../layout/Breadcrumb";
import apiService from "../../../api/apiService";

const BookAppointmentbyClinic = () => {
  const { staff } = useClinicStaff();
  const [bookingDate, setBookingDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [startTime, setStartTime] = useState(dayjs());
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [doctorList, setDoctorList] = useState([]);
  const [patientSearch, setPatientSearch] = useState("");
  const [patientList, setPatientList] = useState([]);
  const [patientData, setPatientData] = useState({
    patientName: "",
    profile: "",
    age: "",
    id: "",
    sex: "",
    patientId: "",
    mobile: "",
    email: "",
    address: "",
  });

  const today = new Date().toISOString().split("T")[0];
  const clinicId = staff.clinicid;
  useEffect(() => {
    fetchDoctors();
  }, []);

  const fetchDoctors = async () => {
    try {
      // const response = await axios.get(
      //   `${process.env.REACT_APP_BASEURL_EHR}/getDoctorListByClinicId/${clinicId}`,
      //   {
      //     headers: {
      //       Authorization: `Bearer ${sessionStorage.getItem(
      //         "staff_access_token"
      //       )}`,
      //     },
      //   }
      // );
      const response = await apiService.getDoctorListByClinicId(clinicId);
      if (response.status === 200) {
        setDoctorList(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedDoctor || !bookingDate) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select both a doctor and date for the appointment.",
      });
      return;
    }

    const formattedDate = dayjs(bookingDate).format("DD-MM-YYYY");

    try {

      // const response = await axios.post(
      //   `${process.env.REACT_APP_BASEURL_EHR}/createAppointment`,
      //   {
      //     patient_id: patientData.id,
      //     fclty_id: staff.clinicid, // replace with actual facility ID
      //     doctor_id: selectedDoctor, // doctor ID from the list
      //     date: formattedDate,

      //     start_time: startTime.format("hh:mm a"),
      //     end_time: startTime.add(10, "minutes").format("hh:mm a"),

      //     created_by: 1,
      //   },
      //   {
      //     headers: {
      //       Authorization: `Bearer ${sessionStorage.getItem(
      //         "staff_access_token"
      //       )}`,
      //     },
      //   }
      // );

      const response = await apiService.createAppointment( {
        patient_id: patientData.id,
        fclty_id: staff.clinicid, // replace with actual facility ID
        doctor_id: selectedDoctor, // doctor ID from the list
        date: formattedDate,

        start_time: startTime.format("hh:mm a"),
        end_time: startTime.add(10, "minutes").format("hh:mm a"),

        created_by: 1,
      });

      if (response.status == 200) {
        Swal.fire({
          icon: "success",
          title: "Appointment Booked",
          text: "Your appointment has been successfully booked.",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response?.data?.response?.message,
      });
    }
  };

  const [docInformations, setDocInformations] = useState(null);
  useEffect(() => {
    const debouncedSearch = debounce(() => {
      searchpatient();
    }, 500); // 500ms delay

    if (patientSearch) {
      debouncedSearch();
    }

    // Clean up debounce
    return () => debouncedSearch.cancel();
  }, [patientSearch]);

  const searchpatient = async () => {
    try {
      const res = await apiService.searchPatient({ searchkey: patientSearch });
      if (res.status === 200) {
        const data = res.data.patientserachresults || [];
         setPatientList(data); // Ensure it sets an array, even if empty
      }
    } catch (error) {
      console.log(error);
      alert("Error: " + error?.response?.data?.response.message);
    }
  };

  const handlePatientSelect = (event, selectedPatient) => {
    console.log("Selected Patient Object:", selectedPatient); // Log the entire object to see if it's null

    if (selectedPatient) {
      const selectedPatientData = patientList.find(
        (patient) => patient.id === selectedPatient.id
      );

      if (selectedPatientData) {
        setPatientData({
          patientName: `${selectedPatientData.firstname} ${selectedPatientData.lastname}`,
          profile: selectedPatientData.profilepic,
          age: selectedPatientData.dob,
          id: selectedPatientData.id,
          sex: selectedPatientData.gender,
          patientId: selectedPatientData.patientid,
          mobile: selectedPatientData.mobilenumber,
          email: selectedPatientData.emailid,
          address: selectedPatientData.address || "",
        });
        console.log("Selected Patient Data:", selectedPatientData);
      } else {
        console.log("Patient not found");
      }
    } else {
      console.log("No patient selected");
    }
  };

  const handleDoc = (e, value) => {
    let doctor = doctorList.find((doc) => doc.doc_id == value.doc_id);
    if (doctor) {
      setDocInformations({
        doctorname: doctor.doctorname,
        fees: doctor.consult_fees,
        time: doctor.time_slot_per_patient,
      });
      setSelectedDoctor(doctor.doc_id);
    } else {
      setDocInformations(null); // Clear if no doctor found
    }
  };

  return (
    <div className="mt-2">
      {/* Nirmal Singh */}

      <div className="container-fluid">
        <Breadcrumb />
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="card shadow mb-4">
              <div className="row card-body">
                <form className="col-md-6">
                  <h6 className="text-start text-primary mb-2">
                    {" "}
                    Booking Details
                  </h6>
                  <Grid container spacing={2} className="mt-1">
                    <Grid item xs={12} md={6}>
                      <TextField
                        size="small"
                        type="date"
                        fullWidth
                        label="Select Date"
                        required
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          min: today,
                        }}
                        value={bookingDate}
                        onChange={(e) => setBookingDate(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LocalizationProvider
                        size="small"
                        dateAdapter={AdapterDayjs}
                      >
                        <TimePicker
                          size="small"
                          label="Select Time"
                          slotProps={{ field: { size: "small" } }}
                          fullWidth
                          value={startTime}
                          onChange={(time) => setStartTime(time)}
                        />
                      </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        size="small"
                        id="free-solo-2-demo"
                        options={doctorList}
                        getOptionLabel={(option) => option.doctorname}
                        onChange={(e, value) => handleDoc(e, value)}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            {...params}
                            label="Search / Select Doctor"
                            slotProps={{
                              input: {
                                ...params.InputProps,
                                type: "search",
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {/* {docInformations && (
        <>
                <p className="m-0">
                  Consultation Fees: {docInformations.fees}
                </p>
                <p className="m-0">Time Slot: {docInformations.time} Min</p>
                </>
            )} */}
                    </Grid>
                  </Grid>

                  {/* <div className="col-md-6 mt-4">
            <Button
              type="submit"
              className="btn btn-outline-dark"
              variant="contained">
              Book Appointment
            </Button>
          </div> */}
                </form>
                <div className="col-md-6">
                  <h6 className="text-primary ">Patients Details</h6>
                  <Grid container spacing={2} className="mt-1">
                    <Grid item xs={12} md={12}>
                      <Autocomplete
                        size="small"
                        id="patient-search"
                        required
                        onChange={(event, newValue) =>
                          handlePatientSelect(event, newValue)
                        } // Pass selected patient
                        onInputChange={(event, newInputValue) =>
                          setPatientSearch(newInputValue)
                        } // Handle search input
                        options={patientList} // Ensure this is an array of patient objects
                        getOptionLabel={(option) =>
                          `${option.firstname} ${option.lastname} ( ${option.mobilenumber} )`
                        }
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            {...params}
                            label="Search Patients  *"
                          />
                        )}
                        freeSolo={false} // Disable free solo typing
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      {patientData && patientData.id && (
                        <div className="row mt-2">
                          <div className="col-md-3 justify-content-end  align-items-center">
                            <img
                              src={`${process.env.REACT_APP_IMAGEURL}/${patientData.profile}`}
                              alt=""
                              className="rounded "
                              loading="lazy"
                              style={{ height: "100px", width: "100px" }}
                            />
                          </div>
                          <div className="col-md-9 justify-content-start d-flex align-items-center ps-4">
                            <div>
                              <h6 className="mb-0">
                                {" "}
                                <strong>patient Name</strong>:{" "}
                                {patientData.patientName}{" "}
                              </h6>
                              <p className="mb-0">
                                {" "}
                                <strong>DOB</strong>:{" "}
                                {dayjs(patientData.age).format("DD-MMM-YYYY")}
                              </p>
                              <p className="mb-0">
                                {" "}
                                <strong>patientID</strong>:{" "}
                                {patientData.patientId}
                              </p>
                              <p className="mb-0">
                                {" "}
                                <strong>Phone</strong>: {patientData.mobile}
                              </p>
                              <p className="mb-0">
                                {" "}
                                <strong>Email</strong>: {patientData.email}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </div>
                <div className="col-md-12 mt-4 text-center">
                  <Button
                    onClick={handleSubmit}
                    type="submit"
                    className="btn btn-outline-dark"
                    variant="contained"
                  >
                    Book Appointment
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookAppointmentbyClinic;
