import React, { createContext, useContext, useEffect, useState } from "react";
import { encryptData, decryptData } from "../../EncryptionUtils/cryptoUtils";
// Create Patient Context
const PatientContext = createContext();

export const PatientProviderPharma = ({ children }) => {
  const [patient, setPatient] = useState(() => {
    const savedStaff = sessionStorage.getItem("patientPharma");
    return savedStaff
      ? JSON.parse(savedStaff)
      : {
          patientName: "",
          profile: "https://via.placeholder.com/100",
          age: "",
          id: "",
          sex: "",
          patientId: "",
          mobile: "",
          email: "",
          address: "",
        };
  });

  useEffect(() => {
    sessionStorage.setItem("patientPharma", JSON.stringify(patient));
  }, [patient]);

  return (
    <PatientContext.Provider value={{ patient, setPatient }}>
      {children}
    </PatientContext.Provider>
  );
};

export const usePatient = () => useContext(PatientContext);
