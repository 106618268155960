import React, { Suspense, lazy} from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loader from "./website/components/loader/Loader";
import Scrolltop from "./website/components/Scrolltop";
import "./App.css";
import "./Clinic_pharma.css";

import ViewPrescriptionModal from "./Epresscription/components/pages/ViewPrescriptionModal";
import BookAppointmentbyClinic from "./Pollyclinic/components/pages/BookAppointmentbyClinic";

// Lazy-loaded components
const Home = lazy(() => import("./website/components/pages/Home"));
const Patient = lazy(() => import("./website/components/pages/Patient"));
const MedicineScheduler = lazy(() =>
  import("./website/components/pages/MedicineScheduler")
);
const Doctor = lazy(() => import("./website/components/pages/Doctor"));
const Clinic = lazy(() => import("./website/components/pages/Clinic"));
const Contact = lazy(() => import("./website/components/pages/Contact"));
const Aboutus = lazy(() => import("./website/components/pages/Aboutus"));
const Blog = lazy(() => import("./website/components/pages/blogs/Blog"));

const BlogMainPage = lazy(() =>
  import("./website/components/pages/blogs/BlogMainPage")
);
const BlogMainPage2 = lazy(() =>
  import("./website/components/pages/blogs/BlogMainPage2")
);
const BlogMainPage3 = lazy(() =>
  import("./website/components/pages/blogs/BlogMainPage3")
);
const Consent = lazy(() => import("./website/components/documents/Consent"));
const PrivacyPolicy = lazy(() =>
  import("./website/components/documents/PrivacyPolicy")
);

const PrivacyPolicyMedicineScheduler = lazy(() =>
  import("./website/components/documents/PrivacyPolicyMedicineScheduler")
);

const ReminderPrivacypolicy = lazy(() =>
  import("./website/components/documents/ReminderPrivacypolicy")
);
const ReminderConsent = lazy(() =>
  import("./website/components/documents/ReminderConsent")
);
const DeleteMyAccount = lazy(() =>
  import("./website/components/documents/DeleteMyAccount")
);

const Notfound = lazy(() => import("./website/components/pages/Notfound"));

// Patient section routes
const PatientPrivateRoute = lazy(() =>
  import("./patientsection/Patient_private_route.jsx")
);

const Completeprofile = lazy(() =>
  import("./patientsection/auth/Completeprofile")
);
const Layout = lazy(() => import("./patientsection/dashboard/Dashboard"));
// const Layout = lazy(() => import("./patientsection/dashboard/Layout"));
const Notes = lazy(() => import("./patientsection/pages/Notes/Note"));
const Healthrecord = lazy(() =>
  import("./patientsection/pages/HealthRecord/Healthrecord")
);

const Uploadrecord = lazy(() =>
  import("./patientsection/pages/HealthRecord/Uploadrecord")
);
const Expenses = lazy(() => import("./patientsection/pages/Expenses/Expenses"));
const Appointment = lazy(() =>
  import("./patientsection/pages/Appointment/Appointment")
);
const Testresults = lazy(() =>
  import("./patientsection/pages/TestResults/Testresults")
);
const Viewmember = lazy(() =>
  import("./patientsection/pages/FamilyMember/Viewmember")
);
const EditProfile = lazy(() => import("./patientsection/pages/EditProfile"));
const Myprofile = lazy(() => import("./patientsection/pages/Myprofile"));
const Addmember = lazy(() =>
  import("./patientsection/pages/FamilyMember/Addmember")
);

const Vitals = lazy(() => import("./patientsection/Vitals"));
const VitalsChartsP = lazy(() =>
  import("./patientsection/VitalsCharts/BloodPressureCharts")
);
const OxygenChartsP = lazy(() =>
  import("./patientsection/VitalsCharts/OxygenCharts")
);
const TempratureChartsP = lazy(() =>
  import("./patientsection/VitalsCharts/TempratureCharts")
);
const BloodGlucoseChartsP = lazy(() =>
  import("./patientsection/VitalsCharts/BloodGlucoseCharts")
);
const PulseChartsP = lazy(() =>
  import("./patientsection/VitalsCharts/PulseCharts")
);
const WeightChartsP = lazy(() =>
  import("./patientsection/VitalsCharts/WeightsCharts")
);

const Demo2 = lazy(() => import("./patientsection/pages/PillScheduler/Demo2"));
const MedicineReminder = lazy(() => import("./patientsection/pages/PillScheduler/MedicineReminder.jsx"))

// Lazy load components E-presscription / Doctor

const Pres4 = lazy(() =>
  import("./Epresscription/components/pages/WritePrescription4")
);
const Pres3 = lazy(() =>
  import("./Epresscription/components/pages/WritePrescription3")
);
const Dashboard = lazy(() =>
  import("./Epresscription/components/layout/Dashboard")
);
const PrescriptionForm = lazy(() =>
  import("./Epresscription/components/pages/PrescriptionForm")
);

const LoginWithOtp = lazy(() => import("./auth/LoginWithOtp"));
const LoginWithPassword = lazy(() => import("./auth/LoginWithPassword"));

const AddNewPatient = lazy(() =>
  import("./Epresscription/components/pages/AddNewPatient")
);
const PrescriptionList = lazy(() =>
  import("./Epresscription/components/pages/PrescriptionList")
);
const ViewPrescription = lazy(() =>
  import("./Epresscription/components/pages/ViewPrescription")
);
const TestResults = lazy(() =>
  import("./Epresscription/components/pages/TestResults")
);
const BloodGlucoseCharts = lazy(() =>
  import("./Epresscription/components/VitalsCharts/BloodGlucoseCharts")
);
const OxygenCharts = lazy(() =>
  import("./Epresscription/components/VitalsCharts/OxygenCharts")
);
const WeightCharts = lazy(() =>
  import("./Epresscription/components/VitalsCharts/WeightsCharts")
);
const TempratureCharts = lazy(() =>
  import("./Epresscription/components/VitalsCharts/TempratureCharts")
);
const PulseCharts = lazy(() =>
  import("./Epresscription/components/VitalsCharts/PulseCharts")
);
const VitalsCharts = lazy(() =>
  import("./Epresscription/components/VitalsCharts/BloodPressureCharts")
);

const DoctorProfile = lazy(() =>
  import("./Epresscription/components/pages/DoctorProfile")
);
const UpdateProfile = lazy(() =>
  import("./Epresscription/components/pages/UpdateDoctorProfile")
);
const SelectClinic = lazy(() =>
  import("./Epresscription/components/pages/SelectClinic")
);
const DoctorPrivateRoutes = lazy(() =>
  import("./Epresscription/components/DoctorPrivateRoute")
);
const DoctorDash = lazy(() =>
  import("./Epresscription/components/pages/DashboardMain2")
);
const EditPatientbyDoc = lazy(() =>
  import("./Epresscription/components/pages/EditPatientByDoctor")
);

// Corrected import Lab
const Shade = lazy(() => import("./Lab/components/layout/Shade"));
const DashboardLab = lazy(() => import("./Lab/components/pages/Dashbaord"));
const Labtest = lazy(() => import("./Lab/components/pages/Labtest"));
const ViewTest = lazy(() => import("./Lab/components/pages/ViewLabtest"));
const Lablist = lazy(() => import("./Lab/components/pages/AllLabtest"));
const LabUser = lazy(() => import("./Lab/components/pages/UserProfile"));
const InvoiceTest = lazy(() => import("./Lab/components/pages/InvoiceTest"));

// polly clicnic
const PrivateRouteStaff = lazy(() =>
  import("./Pollyclinic/components/PrivateRouteStaff")
);
const Dashboardpoly = lazy(() =>
  import("./Pollyclinic/components/layout/Dashboard")
);

const AddNewPatientpoly = lazy(() =>
  import("./Pollyclinic/components/pages/AddNewPatient")
);

const DoctorTiming = lazy(() =>
  import("./Pollyclinic/components/pages/DoctorTiming")
);

const PolyUser = lazy(() =>
  import("./Pollyclinic/components/pages/PollyclinicUser")
);
const DocRegister = lazy(() =>
  import("./Pollyclinic/components/pages/DoctorRegistrationForm")
);
// const Dash2 = lazy(() =>
//   import("./Pollyclinic/components/pages/DashboardMain2")
// );
const Dash2 = lazy(() =>
  import("./Pollyclinic/components/pages/AppointmentDashboard")
);

const PatientQueue = lazy(() =>
  import("./Pollyclinic/components/pages/PatientQueue")
);

const bookappointmentBYClinic = lazy(() =>
  import("./Pollyclinic/components/pages/BookAppointmentbyClinic")
);
const EditPatientByClinic = lazy(() =>
  import("./Pollyclinic/components/pages/EditPatient")
);
const EditDoctorByClinic = lazy(() =>
  import("./Pollyclinic/components/pages/EditDoctorProfilePoly")
);
const AllDoctorList = lazy(() =>
  import("./Pollyclinic/components/pages/AllDocotorList.jsx")
);
//  pharmacy

const ShadePharmacy = lazy(() => import("./Pharmacy/components/layout/Shade"));
const DashboardPharmacy = lazy(() =>
  import("./Pharmacy/components/pages/Dashbaord")
);
const Priscriptionlist = lazy(() =>
  import("./Pharmacy/components/pages/PrescriptionList")
);
const Stock = lazy(() => import("./Pharmacy/components/pages/Stock"));
const IssueMedicinePharmacy = lazy(() =>
  import("./Pharmacy/components/pages/IssueMedicine")
);
const ViewMEdicine = lazy(() =>
  import("./Pharmacy/components/pages/ViewMedicine")
);
const IssuedMedicine = lazy(() =>
  import("./Pharmacy/components/pages/IssuedMedicine")
);
const MakeInvoice = lazy(() =>
  import("./Pharmacy/components/pages/MakeInvoice")
);
const Viewinvoice = lazy(() =>
  import("./Pharmacy/components/pages/ViewInvoice")
);
const PharmaUser = lazy(() => import("./Pharmacy/components/pages/PharmaUser"));

const CopyPres = lazy(() =>
  import("./Epresscription/components/pages/CopyPres.jsx")
);
const TestPres = lazy(() =>
  import("./Epresscription/components/pages/TestPres.jsx")
);
function App() {
  return (
    <BrowserRouter>
      <Scrolltop />
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/login/otp" element={<LoginWithOtp />} />
          <Route path="/login/password" element={<LoginWithPassword />} />
          <Route path="/" element={<Home />} />
          <Route path="patient-ehr" element={<Patient />} />
          <Route path="medicinescheduler" element={<MedicineScheduler />} />
          <Route path="doctor" element={<Doctor />} />
          <Route path="clinic" element={<Clinic />} />
          <Route path="contact" element={<Contact />} />
          <Route path="about" element={<Aboutus />} />
          <Route path="blog" element={<Blog />} />
          <Route path="blogpage1" element={<BlogMainPage />} />
          <Route path="blogpage2" element={<BlogMainPage2 />} />
          <Route path="blogpage3" element={<BlogMainPage3 />} />
          <Route path="consent" element={<Consent />} />
          <Route path="privacypolicy" element={<PrivacyPolicy />} />

          <Route
            path="privacypolicymedicinescheduler"
            element={<PrivacyPolicyMedicineScheduler />}
          />

          <Route
            path="baelhealthreminderpolicy"
            element={<ReminderPrivacypolicy />}
          />
          <Route
            path="baelhealthreminderconsent"
            element={<ReminderConsent />}
          />
          <Route path="deletemyaccount" element={<DeleteMyAccount />} />

          {/* Patient section routes */}

          <Route path="completeprofile/:phone" element={<Completeprofile />} />
          <Route path="/" element={<PatientPrivateRoute />}>
            <Route path="/" element={<Layout />}>
              <Route path="vitals/:id" element={<Vitals />} />
              <Route path="notes" element={<Notes />} />
              <Route path="healthrecord" element={<Healthrecord />} />
              <Route path="uploadrecord" element={<Uploadrecord />} />
              <Route path="test" element={<Testresults />} />
              <Route path="appointment" element={<Appointment />} />
              <Route path="expenses" element={<Expenses />} />
              <Route path="family/viewmember" element={<Viewmember />} />
              <Route path="family/addmember" element={<Addmember />} />
              <Route path="editprofile/:userid" element={<EditProfile />} />
              <Route path="myprofile/:userid" element={<Myprofile />} />

              <Route path="vital" element={<Vitals />} />
              <Route path="vitalschart" element={<VitalsChartsP />} />
              <Route path="oxychart" element={<OxygenChartsP />} />
              <Route path="tempchart" element={<TempratureChartsP />} />
              <Route path="glucochart" element={<BloodGlucoseChartsP />} />
              <Route path="pulsechart" element={<PulseChartsP />} />
              <Route path="weichart" element={<WeightChartsP />} />

              <Route path="medicine-scheduler2" element={<Demo2 />} />
              <Route path="medicine-reminder" element={<MedicineReminder />} />
            </Route>
          </Route>
          {/*  docotor Section */}
          <Route path="/" element={<DoctorPrivateRoutes />}>
            <Route path="selectclinic" element={<SelectClinic />} />
            <Route path="/" element={<Dashboard />}>
              <Route path="profile" element={<DoctorProfile />} />
              {/* <Route path="writeprescription" element={<WritePrescription />} /> */}
              <Route path="addnewpatient" element={<AddNewPatient />} />
              <Route path="form" element={<PrescriptionForm />} />
              <Route path="prescriptionlist" element={<PrescriptionList />} />
              <Route
                path="viewprescription/:pid"
                element={<ViewPrescription />}
              />
              <Route path="testresults" element={<TestResults />} />
              <Route path="vitalschart" element={<VitalsCharts />} />
              <Route path="oxychart" element={<OxygenCharts />} />
              <Route path="tempchart" element={<TempratureCharts />} />
              <Route path="glucochart" element={<BloodGlucoseCharts />} />
              <Route path="pulsechart" element={<PulseCharts />} />
              <Route path="weichart" element={<WeightCharts />} />

              <Route path="viewmodal" element={<ViewPrescriptionModal />} />
              <Route path="updateprofile" element={<UpdateProfile />} />
              <Route path="writeprescription" element={<Pres3 />} />
              <Route path="writeprescription4" element={<Pres4 />} />
              <Route path="doctordash" element={<DoctorDash />} />
              <Route
                path="editpatientbyDoctor"
                element={<EditPatientbyDoc />}
              />

              <Route path="copypres" element={<CopyPres />} />
              <Route path="testPres" element={<TestPres />} />
            </Route>
          </Route>

          {/*  Lab Routes  */}
          <Route path="/" element={<Shade />}>
            <Route path="/dashboardlab" element={<DashboardLab />} />
            <Route path="/labtest" element={<Labtest />} />
            <Route path="/list" element={<Lablist />} />
            <Route path="viewtest/:id" element={<ViewTest />} />
            <Route path="labuser" element={<LabUser />} />
            <Route path="/invoicetest" element={<InvoiceTest />} />
          </Route>

          {/*  Polly clicnic Routes  */}

          <Route path="/" element={<PrivateRouteStaff />}>
            <Route path="/" element={<Dashboardpoly />}>
              <Route path="addnewpatientpoly" element={<AddNewPatientpoly />} />

              <Route path="doctormanagement" element={<AllDoctorList />} />

              <Route path="patientqueue" element={<PatientQueue />} />
              <Route path="addnewpatientpoly" element={<AddNewPatientpoly />} />
              <Route path="doctortime" element={<DoctorTiming />} />
              <Route path="polyuser" element={<PolyUser />} />
              <Route path="docregister" element={<DocRegister />} />
              <Route path="dash2" element={<Dash2 />} />

              <Route
                path="bookbyclinic"
                element={<BookAppointmentbyClinic />}
              />
              <Route
                path="editpatientbyclinic"
                element={<EditPatientByClinic />}
              />
              <Route
                path="editdoctorbyclinic"
                element={<EditDoctorByClinic />}
              />
            </Route>
          </Route>

          {/*   Pharmacy  Routes  */}
          <Route path="/" element={<ShadePharmacy />}>
            <Route path="/dashboardpharmacy" element={<DashboardPharmacy />} />
            <Route
              path="/prescriptionlistPharmacy"
              element={<IssueMedicinePharmacy />}
            />
            {/* <Route path="/invoice" element={<Invoice />} /> */}
            {/* <Route path="/patientslist" element={<PatientsListPharmacy />} /> */}
            <Route path="/stock" element={<Stock />} />
            <Route
              path="/pharmacy/viewmedicine/:id"
              element={<ViewMEdicine />}
            />
            <Route path="issued" element={<IssuedMedicine />} />
            <Route path="makeinvoice" element={<MakeInvoice />} />
            <Route path="viewinvoice/:id" element={<Viewinvoice />} />
            <Route path="pharmauser" element={<PharmaUser />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
