import React, { createContext, useContext, useState, useEffect } from "react";
// Create context
const ClinicContext = createContext();

// Create the ClinicProvider component
export const ClinicProviderEprescription = ({ children }) => {
  // Initialize state from sessionStorage or with default values
  const [clinic, setClinic] = useState(() => {
    const savedClinic = sessionStorage.getItem("clinic");
    return savedClinic
      ? JSON.parse(savedClinic)
      : {
          fclty_doc_id: "",
          fclty_id: "",
          doc_id: "",
          time_slot_per_patient: 20,
          consult_fees: 500,
          followup_fees: 500,
          fclty_nm: "",
          fclty_type: "",
          fclty_identifier: "",
          fclty_govt_id: "",
          fclty_image: "",
          fclty_desc: "",
          fclty_speciality: "",
          parent_fclty_id: 1,
          fclty_contact_person_nm: "",
          fclty_contact_person_cell_num: " ",
          fclty_contact_person_email: "",
          fclty_contact_person_address: "",
          fclty_cell_num: "",
          fclty_email: "",
          fclty_address: "",
          fclty_geo_loc: "",
        };
  });

  // Save clinic state to sessionStorage whenever it changes
  useEffect(() => {
    sessionStorage.setItem("clinic", JSON.stringify(clinic));
  }, [clinic]);

  return (
    // Use ClinicContext.Provider to pass down the clinic state and setter function
    <ClinicContext.Provider value={{ clinic, setClinic }}>
      {children}
    </ClinicContext.Provider>
  );
};

// Custom hook to use the ClinicContext
export const useClinicEprescription = () => useContext(ClinicContext);
