import React, { createContext, useContext, useState, useEffect } from "react";
// Create Doctor Context
const DoctorContext = createContext();

export const DoctorProvider = ({ children }) => {
  // Initialize state from localStorage or with default values
  const [doctor, setDoctor] = useState(() => {
    const savedDoctor = sessionStorage.getItem("doctor");
    return savedDoctor
      ? JSON.parse(savedDoctor)
      : {
          id: "",
          doctorname: "",
          mobilenumber: "",
          emailid: "",
          doctorimage: "",
          address: "",
          loginid: "",
          password: "",
          licenceid: "",
          gender: "",
          dob: "",
          address: "",
          aadharno: null,
          educationaldetails: "",
          yoe: "",
          description: "",
          doctorspcility: "",
          userid: "",
          mci_reg_no: "",
        };
  });

  // Save doctor state to localStorage whenever it changes
  useEffect(() => {
    sessionStorage.setItem("doctor", JSON.stringify(doctor));
  }, [doctor]);

  return (
    <DoctorContext.Provider value={{ doctor, setDoctor }}>
      {children}
    </DoctorContext.Provider>
  );
};

export const useDoctor = () => useContext(DoctorContext);
