import { useContext, useEffect, useState } from "react";
import { createContext } from "react"; 
const ClinicStaffContext = createContext();
export const ClinicStaffUserProvider = ({ children }) => {
  // Initialize state from sessionStorage or with default values
  const [staff, setStaff] = useState(() => {
    const savedStaff = sessionStorage.getItem("staff");
    return savedStaff
      ? JSON.parse(savedStaff)
      : {
          id: "",
          loginid: "",
          clinicid: "",
          password: "",
          licenseid: "",
          type: "",
          profileimg: "",
          name: "",
          staffid: "",
          mobilenumber: "",
          email: "",
          // clinic details
          clinicName: "",
          clinicType: "",
          clinicPhone: "",
          clinicEmail: "",
          clinicImg: "",
          clinicAddress: "",
        };
  });

  // Save staff state to sessionStorage whenever it changes
  useEffect(() => {
    sessionStorage.setItem("staff", JSON.stringify(staff));
  }, [staff]);

  return (
    <ClinicStaffContext.Provider value={{ staff, setStaff }}>
      {children}
    </ClinicStaffContext.Provider>
  );
};

export const useClinicStaff = () => useContext(ClinicStaffContext);
