import React, { createContext, useState, useEffect, useContext } from "react";
const PatientContext = createContext();
const PatientProvider = ({ children }) => {
  // Initialize state from sessionStorage
  const [user, setUser] = useState(() => {
    const storedUser = sessionStorage.getItem("user");
    // console.log(JSON.parse(storedUser));
    return storedUser
      ? JSON.parse(storedUser)
      : {
          id: "",
          mobilenumber: "",
          patientid: "",
          firstname: "",
          lastname: "",
          gender: "",
          dob: "",
          emailid: "",
          address:"",
          profilepic:"",
        };
  });

  // Save user to sessionStorage whenever it changes

  useEffect(() => {
     sessionStorage.setItem("user",  JSON.stringify(user));
  }, [user]);

  // Fetch user from sessionStorage when the component mounts

  return (
    <PatientContext.Provider value={{ user, setUser }}>
      {children}
    </PatientContext.Provider>
  );
};

export { PatientContext, PatientProvider };
